var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',_vm._b({class:[
    'organic-tab-horizontal-item',
    { 'organic-tab-horizontal-item--show': _vm.isActiveTab },
    { 'organic-tab-horizontal-item--first': _vm.isFirstTab },
    { 'organic-tab-horizontal-item--middle': _vm.isMiddleTab },
  ],on:{"click":this.changeTab}},'section',_vm.computedDataAttributes,false),[_c('div',{class:[
      'organic-tab-horizontal-item__title',
      { 'organic-tab-horizontal-item__title--show': _vm.isActiveTab },
      { 'organic-tab-horizontal-item__title--first': _vm.isFirstTab },
      { 'organic-tab-horizontal-item__title--middle': _vm.isMiddleTab },
    ]},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }