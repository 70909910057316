export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    template: {
      type: String,
      validator: function (value) {
        return ['help', 'why'].includes(value)
      },
    },

    dataAttributes: {
      type: Object,
    },

    title: {
      type: String,
    },

    image: {
      type: Object,
    },

    icon: {
      type: String,
    },

    list: {
      type: Array,
    },
  },

  data() {
    return {
      isOpen: this.openByDefault,
      attributes: this.dataAttributes,
    }
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
      this.attributes = this.dataAttributes
    },
  },

  computed: {
    computedDataAttributes() {
      if (this.attributes) {
        const event = this.dataAttributes['data-event-label']
        this.attributes['data-event-details'] = `${this.isOpen ? 'Close' : 'Open'}::${event}`

        return this.attributes
      }
    },
  },
}
