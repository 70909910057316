import AccordionHorizontal from '~/components/Molecules/Organic/AccordionHorizontal/AccordionHorizontal.vue'
import AccordionHorizontalItem from '~/components/Molecules/Organic/AccordionHorizontalItem/AccordionHorizontalItem.vue'
import AccordionHorizontalItemContent from '~/components/Molecules/Organic/AccordionHorizontalItemContent/AccordionHorizontalItemContent.vue'
import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    items: {
      type: Array,
    },
  },

  components: {
    AccordionHorizontal,
    AccordionHorizontalItem,
    AccordionHorizontalItemContent,
  },

  data() {
    return {
      activeIndex: 0,
    }
  },

  mounted() {},

  computed: {},

  methods: {
    toggle(index) {
      this.activeIndex = index
    },
  },
}
