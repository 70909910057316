import device from '~/mixins/device.js'

export default {
  mixins: [device],

  props: {
    title: {
      type: String,
    },

    dataAttributes: {
      type: Object,
    },

    image: {
      type: Object,
    },

    idToScroll: {
      type: String,
    },

    content: {
      type: Object,
    },

    toggle: {
      type: Function,
    },

    index: {
      type: Number,
      default: 0,
    },

    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      attributes: this.dataAttributes,
    }
  },

  computed: {
    isActiveTab() {
      return this.activeIndex === this.index
    },

    isFirstTab() {
      return 0 === this.index && this.activeIndex !== this.index
    },

    isMiddleTab() {
      return 1 === this.index && this.activeIndex !== this.index && this.activeIndex !== 0
    },

    computedDataAttributes() {
      if (this.attributes) {
        const event = this.dataAttributes['data-event-label']
        this.attributes['data-event-details'] = `${this.isActiveTab ? 'Close' : 'Open'}::${event}`

        return this.attributes
      }
    },
  },

  methods: {
    changeTab() {
      this.toggle(this.index)
      var element = document.getElementById(this.idToScroll)

      if (element && !this.isDesktop) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  },
}
