import debounce from 'lodash.debounce'
import device from '~/mixins/device.js'

export default {
  mixins: [device],

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },
  },

  data() {
    return {
      index: 0,
      unpinned: false,
      solid: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.header = document.querySelector('.organic-tab-horizontal-item__title')
      this.page = document.querySelector('.organic-why-choose-hotmart')

      let { y: previousScrollPositionY } = this.scrollPosition()
      this.previousScrollPositionY = previousScrollPositionY

      window.addEventListener('scroll', this.scroll, { capture: true, passive: true })

      window.dispatchEvent(new Event('scroll'))
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll)
  },

  computed: {},

  methods: {
    scroll(e) {
      if (!this.isDesktop) {
        this.scrollDebounce(e, this)
      } else {
        this.solid = false
        this.unpinned = false
      }
    },

    scrollDebounce: debounce((e, self) => {
      const { y: currentScrollPositionY } = self.scrollPosition()

      if (self.isScrollDown()) {
        self.unpinned = true
        if (self.isOutsideSafetyMarginHeader()) self.solid = true
        else self.solid = false
      }
      if (self.isScrollUp()) {
        self.unpinned = false
        if (self.isOutsideSafetyMarginHeader()) self.solid = true
        else self.solid = false

        if (self.headerDetached()) self.hasTop = true
        else self.hasTop = false
      }

      self.previousScrollPositionY = currentScrollPositionY
    }, 16),

    scrollPosition() {
      let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollMinY = 0
      const scrollMaxY =
        window.scrollMaxY || document.getElementById('__layout').scrollHeight - document.documentElement.clientHeight

      if (scrollPositionY < scrollMinY) scrollPositionY = scrollMinY
      if (scrollPositionY > scrollMaxY) scrollPositionY = scrollMaxY

      return {
        y: scrollPositionY,
      }
    },

    headerDetached() {
      const { y: currentScrollPositionY } = this.scrollPosition()

      return Boolean(currentScrollPositionY > 100)
    },

    isScrollDown() {
      const { y: currentScrollPositionY } = this.scrollPosition()
      const isScrollDown = this.previousScrollPositionY < currentScrollPositionY

      return isScrollDown
    },

    isScrollUp() {
      return !this.isScrollDown()
    },

    isOutsideSafetyMarginHeader() {
      const { height: headerHeight } = this.header.getBoundingClientRect()
      const { top: pageOffsetTop, height: divHeight } = this.page.getBoundingClientRect()
      const headerSafetyMargin = headerHeight * 2.2
      const limit = divHeight - divHeight * (30 / 100)

      return headerSafetyMargin < -pageOffsetTop && -pageOffsetTop < limit
    },
  },
}
