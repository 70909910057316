var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"organic-accordion-horizontal-items"},[_c('div',{ref:"animatedDiv",class:[
      'organic-accordion-horizontal-items__titles',
      { 'organic-accordion-horizontal-items__titles--unpinned': _vm.unpinned },
      { 'organic-accordion-horizontal-items__titles--solid': _vm.solid },
    ]},[_vm._t("title")],2),_vm._v(" "),_c('div',{class:[
      'organic-accordion-horizontal-items__contents',
      { 'organic-accordion-horizontal-items__contents--solid': _vm.solid },
    ]},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }