import modalSignup from '~/mixins/modalSignup.js'
import hotOrganicButton from '~/components/Atoms/Organic/Button/Button.vue'

export default {
  mixins: [modalSignup],

  components: {
    hotOrganicButton,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    content: {
      type: Object,
    },

    dataAttributes: {
      type: Object,
    },

    cta: {
      type: Object,
    },

    index: {
      type: Number,
      default: 0,
    },

    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  mounted() {},

  computed: {},

  methods: {},
}
